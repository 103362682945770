<template>
	<div class="popup active">
		<div class="notice_popup human int">
			<div class="close">
				<img src="/images/close.png" alt="닫기" @click="$emit('close', {flag :false})"/>
			</div>
			<div class="tit">
				<span v-if="displayDiv == '01'">소속 승인</span>
				<span v-else-if="displayDiv == '02'">소속 탈퇴</span>
				<span v-else-if="displayDiv == '03'">프로젝트 참여소속 승인</span>

				<!-- <span v-else-if="displayDiv == '02'">소속 변경</span>
				<span v-else-if="displayDiv == '03'">프로젝트 참여소속 승인</span>
				<span v-else-if="displayDiv == '04'">프로젝트 참여소속 변경</span> -->
			</div>
			<div class="member" style="line-height: 40px;">
				<span v-if="displayDiv == '01' ">
					<span class="name">
						<strong>{{mberNm}} (<SelectComp type="text" list="F:여,M:남" v-model="gender" />/
							<span v-if="empDivCd == '' || empDivCd == null"> - )</span> 
							<span v-else><SelectComp type="text" cdId="PRO102" v-model="empDivCd" />)</span>
						</strong>님의 소속을
					</span> 
					<br />
					<!-- <span class="corp">&lsquo;{{aftCorpNm}} &rsquo;</span> 으로<br /> -->
					<span class="corp">&lsquo;{{aftCorpNm}} (<SelectComp type="text" cdId="PRO102" v-model="aftEmpDivCd" />)&rsquo;</span> 으로<br />
					승인 하시겠습니까?
				</span>
				<span v-else-if="displayDiv == '02'">
					<span class="name"><strong>{{mberNm}} (<SelectComp type="text" list="F:여,M:남" v-model="gender" />/ <SelectComp type="text" cdId="PRO102" v-model="empDivCd" />)</strong>님의</span> <br />
					소속 탈퇴요청을 승인 하시겠습니까?
				</span>

				<span v-else-if="displayDiv == '03'">
					<span class="name"><strong>{{mberNm}} (<SelectComp type="text" list="F:여,M:남" v-model="gender" />/<SelectComp v-if="empDivCd" type="text" cdId="PRO102" v-model="empDivCd" /><span v-else>-</span>)</strong>님의 참여소속을</span> <br />
					<span class="corp">&lsquo;{{aftCorpNm}} &rsquo;</span> 으로<br />
					승인 하시겠습니까?
				</span>

				<!-- <span v-else-if="displayDiv == '02'">
					<span class="name"><strong>{{mberNm}} (<SelectComp type="text" list="F:여,M:남" v-model="gender" />/ <SelectComp type="text" cdId="PRO102" v-model="empDivCd" />)</strong>님의</span> <br />
					소속 변경요청을 승인 하시겠습니까?
				</span>
				<span v-else-if="displayDiv == '03'">
					<span class="name"><strong>{{mberNm}} (<SelectComp type="text" list="F:여,M:남" v-model="gender" />/ <SelectComp type="text" cdId="PRO102" v-model="empDivCd" />)</strong>님의 참여소속을</span> <br />
					<span class="corp">&lsquo;{{aftCorpNm}} &rsquo;</span> 으로<br />
					승인 하시겠습니까?
				</span>
				<span v-else-if="displayDiv == '04'">
					<span class="name"><strong>{{mberNm}} (<SelectComp type="text" list="F:여,M:남" v-model="gender" />/ <SelectComp type="text" cdId="PRO102" v-model="empDivCd" />)</strong>님의 참여소속을</span> <br />
					<span class="corp">&lsquo;{{preCorpNm}}&rsquo;</span> 에서 <span class="corp">&lsquo;{{aftCorpNm}}&rsquo;</span> 으로 <br />
					참여소속 변경요청을 승인 하시겠습니까?
				</span> -->
			</div>
			
			<div class="select-btn">
				<div class="btn" @click="$emit('close', {flag :true , apprYn : 'N'})">승인불가</div>
				<div class="btn" @click="$emit('close', {flag :true , apprYn : 'Y'})">승인</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:['param'],
	data() {
		return {
			mberNm: this.param.mberNm,
			gender: this.param.gender,
			empDivCd: this.param.empDivCd,

			classDivCd :  this.param.classDivCd,
			corpApprDivCd  : this.param.corpApprDivCd,
			aftEmpDivCd : this.param.aftEmpDivCd,
			aftCorpNm :  this.param.aftCorpNm,
			
			displayDiv : ''

			//joinCorpApprDivCd  : this.param.joinCorpApprDivCd,
			//preCorpNm :  this.param.preCorpNm,
			//asgDiv :  this.param.asgDiv,
		}
	},
	mounted(){
		if(this.corpApprDivCd == '01'){
			this.displayDiv = '01'
		}else if(this.corpApprDivCd == '02'){
			this.displayDiv = '02'
		}else if(this.corpApprDivCd == '03'){
			this.displayDiv = '03'
		}

		// if((this.classDivCd == '01' || this.classDivCd == '02') && this.corpApprDivCd == '01'){
		// 	this.displayDiv = '01'
		// }else if(this.classDivCd == '02' && this.corpApprDivCd == '03'){
		// 	this.displayDiv = '02'
		// }else if(this.classDivCd == '03' && this.joinCorpApprDivCd == '01'){
		// 	this.displayDiv = '03'
		// }else if(this.classDivCd == '03' && this.joinCorpApprDivCd == '03'){
		// 	this.displayDiv = '04'
		// }
	}
}
</script>